import { useCallback, useEffect, useMemo } from "react";
import { filterStore, pageStore, mapStore, userStore } from "state/store-zustand";

// TODO: Load from design tokens
const blue = "#007acc"
const darkblue = "#004175"
const lightblue = "#e6f4ff"
const green = "#55BA47"
const darkgreen = "#3D8B3D"
const lightgray = "#f6f6ff"
const pink = "#ff1f78"
const lightpurple = 'hsl(253, 74%, 68%)'
const darkpurple = 'hsl(253, 74%, 68%)'

// TODO: Make a hook that updates these based on the theme
const numColor = darkblue
const numTheme = 'dark' // 'dark' or 'light'
const numTextColor = numTheme == 'dark' ? '#fff' : '#111'
const numBgColor = numColor
  ? numColor
  : numTheme == 'dark'
    ? '#111'
    : '#fff'

const numBorderColor = numTheme == 'dark' ? '#fff' : '#CCC'


// TODO: placeholder for this coming from the API
const themes = {
  default: {
    accent: "#332F36",
    foreground: "#222222",
    background: "#FFFFFF",
    color: "#000000",
    numTextColor: numTextColor,
    numBadgeSize: 24,
    numBgColor: numBgColor,
    numBorderColor: numBorderColor,
    numTheme: numTheme,
    eventForm: {
      title: 'Add Your Event',
      message: 'Search to add or update a place or event to Vibemap. Send any questions or suggestions. <a href="mailto:info@vibemap.com"> info@vibemap.com</a>',
      showVibePoints: true,
    },
    cards: {
      open_to_website: false
    },
    events: {
      // Can control the vibes, categories, and tags shown
    },
    map: {
      boundary: {
        fill_color: lightpurple,
        fill_opacity: 0.1,
        line_color: darkblue,
        line_opacity: 0.8,
        line_weight: 4,
        line_dash_array: [3, 2], // [1.4, 1]
      },
      routeColor: "#9115CB",
    },
  },
  light: {
    foreground: "#FFFFFF",
    background: "#222222",
    color: "#000000",
  },
  clermont: {
    logo: 'https://discoverclermont.com/media/04wf0iin/discover_clermont_logo_rgb.jpg',
    cards: {
      open_to_website: true,
      open_to_page: (type, slug) => 'https://discoverclermont.com/events/details?id='
    },
    events: {
      link: 'https://on.vibemap.com/events-clermont'
    }
  },
  cleveland: {
    logo: 'https://images.squarespace-cdn.com/content/v1/645ba4265ac81f7c3a42f05d/3ee82205-2e06-4be7-8c13-1c84748839e4/DTCLE-Logo.png?format=300w',
    cards: {
      open_to_website: true
    },
    events: {
      link: 'https://on.vibemap.com/events_downtown_cleveland'
    }
  },
  downtown_tulsa: {
    cards: {
      open_to_website: true, // Better description is open to vibemap
      open_to_page: (type, slug) => 'https://downtowntulsa.com/do/'
    },
    events: {
      link: 'https://on.vibemap.com/downtown-tulsa-events',
      link_newsletter: 'https://on.vibemap.com/downtown-tulsa-events-for-newsletter',
    },
    eventForm: {
      title: 'Add Your Event',
      message: 'Search for your event or enter the info below. Send any questions or suggestions. <a href="mailto:info@downtowntulsa.com">info@downtowntulsa.com</a>',
      showIntroMessage: false,
      showVibePoints: false,
    },
    logo: 'https://ctycms.com/ok-tulsa/tulsa-logo-600x400.svg'
  },
  downtown_portland: {
    cards: {
      open_to_website: true // Better description is open to vibemap
    },
    map: {
      boundary: {
        fill_color: '#E4A682', // Something old and brick like
        fill_opacity: 0.4,
        line_color: '#cf2e2e',
        line_opacity: 0.8,
        line_weight: 2,
        line_dash_array: [3, 2], // [1.4, 1]
      },
    }
  },
  evanston: {
    cards: {
      open_to_website: true
    },
    events: {
      link: 'https://on.vibemap.com/evanston-events',
      randomizeImages: true,
    },
    map: {
      //heightMobile: "65vh", // Not used
      heightOffset: -200,
      showHeat: false,
      markerFeatured: {
        'background-color': '#007acc',
        'border-color': '#007acc',
        'latitude': 42.0511,
        'longitude': -87.6784,
        'name': null,
        'image': 'https://downtownevanston.org/sites/downtown-evanston/images/dte-logo.png',
        'style': 'marker', // Or tooltip
        'persistent': true,
      },
      showScrollArea: false,
      titleCards: {
        'Evanston_Trick_Treat': {
          id: "363f8183-aa56-4d85-99f1-1c2dd8727ab4",
          slug: "fountain-square-1",
          title: 'Downtown Evanston Trick or Treat',
          subtitle: 'Start Here!',
          description: '<div class="note">🎃Evanston Trick or Treat: Start Here and pick up your map.</div> Bring your kids downtown to trick or treating at local businesses! Stroll to participating downtown businesses for candy and other goodies. <br/> <strong>Note</strong>: that not all businesses are participating so look for spooky posters on the doors. Some businesses will have candy outside.',
          image: 'https://ik.imagekit.io/vibemap/evaston/Downtown%20Evanston%20%20Trick%20or%20Treat_nmIpzFIwO.jpg?updatedAt=1698274466089',
          geometry: {
            "type": "Point",
            "coordinates": [
              -87.6815944,
              42.047158
            ]
          },
          showAsCard: true,
          "vibes": [
            "family",
            "kids",
            "outdoors",
            "free"
          ]
        }
      }
    }
  },
  "experience-montclair": {
    cards: {
      open_to_website: true,
      open_to_page: (type, slug) => `https://experiencemontclair.org/${type ? type : 'event'}-details/?id=${slug ? slug : ''}`  ,
      use_dynamic_link: true
    },
  },
  harlem: {
    cards: {
      open_to_website: true // Better description is open to vibemap
    },
    events: {
      link: 'https://on.vibemap.com/125th-harlem-events'
    },
    map: {
      boundary: {
        fill_color: 'hsl(253, 74%, 68%)',
        fill_opacity: 0.2,
        line_color: darkblue,
        line_opacity: 0.8,
        line_weight: 3,
        line_dash_array: [4, 0], // [1.4, 1]
      }
    },
  },
  "la-jolla": {
    cards: {
      open_to_website: true,
      open_to_page: (type, slug) => 'https://lajollabythesea.com/event-details/?id='
    },
    events: {
      randomizeImages: true,
      relatedEvents: true,
      relatedEventsRadius: 0.5,
    },
    map: {
      boundary: {
        fill_color: '#ff5d37',
        fill_opacity: 0.08,
        line_color: '#ff5d37',
        line_opacity: 0.6,
        line_weight: 3,
        line_dash_array: [2, 1], // [1.4, 1]
      },
    },
  },
  "lynchburg-downtown": {
    cards: {
      open_to_website: true,
      open_to_page: (type, slug) => `https://downtownlynchburg.com/${type ? type : 'event'}-details/?id=${slug ? slug : ''}`,
      use_dynamic_link: true
    },
    map: {
      boundary: {
        fill_color: '#801643',
        fill_opacity: 0.08,
        line_color: '#801643',
        line_opacity: 0.6,
        line_weight: 3,
        line_dash_array: [2, 1], // [1.4, 1]
      },
      routeColor: "#9115CB",
    },
  },
  "montclair": {
    logo: 'https://www.downtownpittsburgh.com/wp-content/uploads/2021/02/Downtown-Pittsburgh-Logo-Color.png',
    cards: {
      open_to_website: true
    },
    events: {
      link: 'https://on.vibemap.com/events-downtown-pittsburgh'
    }
  },
  "oakland-downtown": {
    cards: {
      open_to_website: true
    },
    filters: {
      tagsToExclude: ['Oakland_Attractions', 'Oakland_Fairyland', 'Oakland_Museum', 'OAKRestaurantWeek2024', 'Play']
    },
    map: {
      boundary: {
        fill_opacity: 0.2,
        fill_color: [
          "match",
          ["get", "name"],
          ["Chinatown", "Chinatown-Laney"],
          "#c8662e",
          ["Lakeside"],
          "#56c2ab",
          ["Uptown"],
          "#2d8cc0",
          ["", "Downtown"],
          "#57b47e",
          ["Old Oakland"],
          "#c0c01b",
          ["Jack London "],
          "#d49426",
          ["Jack London Square"],
          "#f9c89a",
          "#f2e2a1"
        ],
        line_color: "#000000",
        line_opacity: 0.2,
        line_weight: 1,
        show_labels: true,
      }
    }
  },
  peoria: {
    foreground: "#FFFFFF",
    background: "#222222",
    color: "#000000",
    filters: {
      tagsToExclude: ['Play_in_Peoria', 'Default']
    },
    events: {
      showOnlyEventTags: false,
    },
    eventForm: {
      title: 'Add Your Event',
      link: 'https://peoria.org/events/submit-your-event',
      message: 'Search to add or update a place or event to Vibemap. Send any questions or suggestions. <a href="mailto:info@vibemap.com"> info@vibemap.com</a>',
      showIntroMessage: false,
      showVibePoints: false,
    },
    showLogin: false,
  },
  pittsburgh: {
    logo: 'https://www.downtownpittsburgh.com/wp-content/uploads/2021/02/Downtown-Pittsburgh-Logo-Color.png',
    cards: {
      open_to_website: true
    },
    events: {
      link: 'https://on.vibemap.com/events-downtown-pittsburgh'
    },
    map: {
      boundary: {
        fill_color: '#FFEE79',
        fill_opacity: 0.2,
        line_color: '#FF8C00',
        line_opacity: 0.8,
        line_weight: 2,
        line_dash_array: [3, 2], // [1.4, 1]
      },
    }
  },
  riverside: {
    accent: "#ed1c80",
    numBadgeSize: 24,
    numBgColor: "#ed1c80",
    numTextColor: "#FFF",
    cards: {
      open_to_website: true
    },
    map: {
      boundary: {
        fill_color: lightgray,
        fill_opacity: 0.1,
        line_color: darkblue,
        line_opacity: 0.8,
        line_weight: 4,
        line_dash_array: [3, 2], // [1.4, 1]
      },
      routeColor: "#ed1c80",
      setShowCardNum: false,
    }
  },
  soma_west: {
    events: {
      vibes: ['art', 'cultural', 'free', 'family', 'food', 'music', 'dance']
    }
  },
  springfield: {
    map: {
      boundary: {
        fill_opacity: 0.0,
        line_opacity: 0.0,
      }
    }
  },
  toledo: {
    logo: 'https://www.downtowntoledo.org/media/384259/downtowntoledologo_vertical_rgb.jpg',
    cards: {
      open_to_website: true
    },
    events: {
      link: 'https://on.vibemap.com/events-toledo'
    }
  },
  tucson: {
    map: {
      shouldIncludeTags: true,
      heightOffset: -200,
    }
  },
  union_square: {
    cards: {
      open_to_website: true
    },
    events: {
      vibes: ['art', 'free', 'family', 'food', 'music', 'dance', 'educational', 'business']
    },
    filters: {
      tagsToInclude: ['Design', 'Company', 'Family', 'Health & Wellness', 'Thrift']
    },
    map: {
      boundary: {
        fill_color: "#99DBE4",
        fill_opacity: 0.2,
        line_color: "#29737E",
        line_opacity: 0.6,
        line_weight: 4,
        line_dash_array: [3, 2], // [1.4, 1]
      },
      categoryColors: {
        'art': 'blue',
        'health & wellness': 'green',
        'cultural': 'pink',
        'dance': 'green',
        'design': 'blue',
        'home decor': 'blue',
        'thrift': 'purple',
      },
      tagNames: {
        'Design': 'Design + Home Goods',
        'Company': 'Tech + Innovation',
        'Health & Wellness': 'Lifestyle + Fitness',
        'Thrift': 'Thrift + Vintage',
        'Family': 'Family-Friendly',
      }
    }
  },
  westshore: {
    logo: 'https://www.choosewestshore.com/wp-content/themes/westshore-alliance/dist/img/logo.png',
    events: {
      link: 'https://on.vibemap.com/westshore-events',
      eventLayout: 'columns',
      tagAuto: ['Westshore Tampa'],
    },
    filters: {
      tagsToExclude: [
        'American', 'lunch',
        'tampa_places', 'westshore', 'bar', 'stores', 'event_venue', 'event rentals',
        'Fishing', 'dog park', 'hiking', 'market', 'nature trails', 'shopping centers', 'lounge']
    },
    map: {
      titleCards: {
        'westshore_art': {
          id: "363f8183-aa56-4d85-99f1-1c2dd8727ab4",
          slug: "fountain-square-1",
          title: 'Westshore Tampa Public Art',
          subtitle: 'Start Here!',
          description: 'This map showcases artists who are expressing the culture, history, and beauty of Westshore Tampa is eyecatching, murals and large-format artwork for all the public to enjoy.',
          image: 'https://ik.imagekit.io/vibemap/evaston/Downtown%20Evanston%20%20Trick%20or%20Treat_nmIpzFIwO.jpg?updatedAt=1698274466089',
          geometry: {
            "type": "Point",
            "coordinates": [
              -87.6815944,
              42.047158
            ]
          },
          showAsCard: false,
          "vibes": [
            "family",
            "kids",
            "outdoors",
            "free"
          ]
        }
      }
    }
  },
  westwood: {
    logo: 'https://thewestwoodvillage.com/wp-content/themes/westwood-village/images/common/logo-header-v2.png',
    cards: {
      open_to_website: true
    },
    events: {
      link: 'https://on.vibemap.com/westwood_village_events',
      randomizeImages: true,
    }
  }
}


export const loadFonts = (themeName) => {

  const appendLinkElement = (attributes) => {
    const link = document.createElement('link');
    Object.entries(attributes).forEach(([key, value]) => {
      link[key] = value;
    });
    document.head.appendChild(link);
  };

  // Preconnect links for all themes
  const preconnectLinks = [
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossOrigin: 'anonymous' },
  ];

  preconnectLinks.forEach(appendLinkElement);


  // Theme-specific font links
  const themeFonts = {
    'la-jolla': 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
    'lynchburg-downtown': 'https://use.typekit.net/vnc5qwg.css',
    'oakland-downtown': 'https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap',
    'westwood': 'https://fonts.googleapis.com/css2?family=Righteous&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
    'federal-hill': 'https://fonts.googleapis.com/css2?family=Roboto+Slab%3A400%2C%7CLexend+Peta%3A500%2C%2C600&display=fallback',
    'pittsburgh': 'https://fonts.googleapis.com/css2?family=Gilda+Display&family=Heebo:wght@100..900&display=swap',
    'yerba-buena': 'https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&display=swap',
  };

  // Append theme-specific font link
  if (themeFonts[themeName]) {
    appendLinkElement({ rel: 'stylesheet', href: themeFonts[themeName] });
  }
}


const useContextTheme = () => {

  const {
    setShowHeat,
    setShouldIncludeTags
  } = mapStore((state) => state);

  const {
    tagsCurrent,
    setTagsCurrent,
    setShowOnlyEventTags
  } = filterStore((state) => state);

  const {
    themeName,
    setThemeName,
    themeObject,
    setShowLogin,
    setThemeObject,
    setThemeClass,
  } = pageStore((state) => state);

  const {
    navItems,
    setNavItems
  } = userStore((state) => state);

  const mainTheme = themeName
  const whichTheme = themeName ? themeName : 'default'

  // Once theme is set, update the global state
  const themeObjectDep = JSON.stringify(themeObject)
  const memoizedThemeObject = useMemo(() => themeObject, [themeObject]);

  useEffect(() => {
    if (themeObject?.map?.showHeat !== undefined) {
      setShowHeat(themeObject.map.showHeat);
    }

    if (themeObject?.map?.shouldIncludeTags !== undefined) {
      setShouldIncludeTags(themeObject.map.shouldIncludeTags);
    }

    if (themeObject?.showLogin !== undefined) {
      setShowLogin(themeObject.showLogin)
    }

    if (themeObject?.events?.showOnlyEventTags !== undefined) {
      setShowOnlyEventTags(themeObject.events.showOnlyEventTags)
    }

    if (themeObject?.events?.link) {

      //const newItems = navItems.filter(item => item.title !== 'Calendar of Events')
      const updatedItems = navItems.map(item => {
        if (item.title === 'Calendar of Events') {
          item.path = themeObject?.events?.link
        }
        if (item.title === 'Share via Newsletter') {
          const themeArg = `${ themeName ? '&theme=' + themeName : ''}`
          item.path = themeObject?.events?.link_newsletter
            ? themeObject?.events?.link_newsletter
            : themeObject?.events?.link
              ? themeObject?.events?.link + '?compact=1' + themeArg
              : '?compact=1' + themeArg
        }

        // Make sure theme is set in all links
        if (item.path.includes(themeName) == false) {
          item.path = item.path + `?theme=${themeName}`
        }

        return item
      })

      setNavItems(updatedItems)
    }

    if (themeObject?.events?.eventLayout) {
      pageStore.setState({ eventLayout: themeObject.events.eventLayout })
    }

    if (themeObject?.events?.tagAuto) {
      const tagsTheme = [
        ...tagsCurrent,
        ...themeObject.events.tagAuto.filter(tag => !tagsCurrent.includes(tag))
      ]

      setTagsCurrent(tagsTheme)
    }

  }, [
    whichTheme,
    themeObjectDep
  ])

  // Get the current theme and merge with default
  const hasHyphen = whichTheme.includes('-')
  const currentTheme = themes[whichTheme]
    ? themes[whichTheme]
    : hasHyphen
      ? themes[whichTheme.replace('-', '_')] // Fix cases like 'downtown-tulsa'
      : themes.default


  const themeTokens = {
    ...themes.default,
    ...currentTheme
  }

  useEffect(() => {
    const themeTokens = themes[whichTheme] || themes.default
    const themeChanged = JSON.stringify(themeTokens) !== JSON.stringify(themeObject)
    if (themeChanged && themeTokens) {
      setThemeObject(themeTokens)
      setThemeClass(`theme-${whichTheme}`)
    }

    // Load fonts
    loadFonts(whichTheme)

  }, [whichTheme]);


  //setThemeName(whichTheme)
  //setThemeObject(themeTokens)

  return {
    themeClass: `theme-${whichTheme}`,
    theme: themeTokens
  }
}

export default useContextTheme